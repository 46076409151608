<template>
  <div class="advantages-wrapper" v-if='loaded'>
    <div class="container">
      <div class="advantages-description">
        <h1>{{title}}</h1>
        <p>
          {{
            text
          }}
        </p>
      </div>

      <div class="advantages-cards">
        <card
          v-for="(val, index) in advantages"
          :key="index"
          :content="val"
        ></card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./UI/AdvantagesCard.vue";
import img1 from "../assets/img/advantages_1.svg";
import img2 from "../assets/img/advantages_2.svg";
import img3 from "../assets/img/advantages_3.svg";
import img4 from "../assets/img/advantages_4.svg";
import img5 from "../assets/img/advantages_5.svg";
import img6 from "../assets/img/advantages_6.svg";

export default {
  components: { Card },
  data() {
    return {
      loaded: false,
      title: '',
      text: '',
      advantages: [
        {
          poster: img1,
          title: "Все виды образовательных программ",
          description:
            "Участие в международных и отечественных программах по повышению квалификации работников финансовой системы",
        },
        {
          poster: img2,
          title: "20 000 довольных клиентов",
          description:
            "Все слушатели окончившие наши курсы отзываются положительно как о качестве преподавания так и об объёме полученных знаний и информации",
        },
        {
          poster: img3,
          title: "20 лет успешного опыта",
          description:
            "Большой опыт и стабильная положительная репутация по праву дают нам возможность называться одним из самых сильных образовательных учреждений",
        },
        {
          poster: img4,
          title: "Самое крупное образовательное учреждение",
          description:
            "Работа во всех регионах Казахстана и ближнего зарубежья проведение корпоративных семинаров, обучение в группах",
        },
        {
          poster: img5,
          title: "Все виды образовательных программ",
          description:
            "Участие в международных и отечественных программах по повышению квалификации работников финансовой системы",
        },
        {
          poster: img6,
          title: "Высоко - квалифицированные преподаватели",
          description:
            "Сертифицированные преподаватели, лектора и эксперты помогут освоить самые трудные предметы и разобраться во всех тонкостях науки",
        },
      ],
    };
  },
  mounted(){
    this.title = this.$store.state.content.advantages.title
    this.text = this.$store.state.content.advantages.text
    this.advantages = this.$store.state.content.advantages.items
    this.loaded = true
  }
};
</script>

<style></style>
