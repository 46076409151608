<template>
  <div class="accordion">
    <h1>Частые вопросы?</h1>
    <item
      v-for="(item, index) in items"
      :key="index"
      :content="item.description"
      :header="item.title"
    ></item>
  </div>
</template>

<script>
import Item from "./AccordionItem.vue";

export default {
  props: ["items"],
  components: { Item },
};
</script>

<style lang="scss">
// @import '../../_vars.scss';
.accordion {
  margin: 2rem 0;
}
</style>
