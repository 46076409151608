<template>
  <div class="review white">
    <h3>{{ review.title }}</h3>
    <span v-if="review.city" class="review-city gray"
      >{{ review.city }}<br /></span
    ><br />
    <span v-html="review.description"> </span>
  </div>
</template>

<script>
export default {
  props: ["review"],
};
</script>

<style></style>
