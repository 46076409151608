<template>
  <div id="mobile" :style="{ height: showModal ? '100vh' : 'auto' }">
    <div id="app-modal">
      <vue-final-modal
        classes="modal-container"
        content-class="modal-content"
        v-model="modals.menu"
        attach="#app-modal"
        :lock-scroll="false"
        name="menuModal"
      >
        <h3>
          Меню
          <svg
            @click="toggleModal('menu')"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#F5F5F7" />
            <path
              d="M8 8L16 16"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 16L16 8"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 8L16 16"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 16L16 8"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h3>
        <div class="site-drawer">
          <a href='https://hseipaa.kz/' class="site-drawer-item hseiipa-kz">
            <div class="image-wrapper">
              <img src="../assets/img/logo.svg" alt="hseiippa-logo" />
            </div>
            <span class="primary-red"> Hseiipa.kz </span>
          </a>
          <div @click="handleMain()" class="site-drawer-item hseiipa-online active">
            <div class="image-wrapper">
              <img src="../assets/img/logo.svg" alt="hseiippa-logo" />
            </div>
            <span class="primary-gray"> Hseiipa online </span>
          </div>
          <a href='https://hseipaa.kz/' class="site-drawer-item hseiipa-portal">
            <div class="image-wrapper">
              <img src="../assets/img/logo.svg" alt="hseiippa-logo" />
            </div>
            <span class="primary-gray"> Портал бухгалтеров </span>
          </a>
        </div>

        <div class="lang-mobile">
          <div
            class="lang-mobile-item lang-kz"
            :class="$store.state.lang == 'kk' ? 'active' : ''"
            @click="$store.dispatch('changeLang', 'kk')"
          >
            Қаз
          </div>
          <div
            class="lang-mobile-item lang-ru"
            :class="$store.state.lang == 'ru' ? 'active' : ''"
            @click="$store.dispatch('changeLang', 'ru')"
          >
            Рус
          </div>
          <div
            class="lang-mobile-item lang-en"
            :class="$store.state.lang == 'en' ? 'active' : ''"
            @click="$store.dispatch('changeLang', 'en')"
          >
            Eng
          </div>
        </div>

        <ul class="mobile-menu-links">
          <li>
            <a
              href="https://on.hseipaa.kz/courses"
              active-class="primary-red"
              to="/"
              >Курсы</a
            >
          </li>
          <li>
            <router-link
              @click="closeAllModals()"
              active-class="primary-red"
              to="/about"
              >О нас</router-link
            >
          </li>
          <li>
            <router-link
              @click="closeAllModals()"
              active-class="primary-red"
              to="/contacts"
              >Контакты</router-link
            >
          </li>
          <li class="primary-red" style="padding: 0.7rem 0">hseipaa.kz</li>
        </ul>

        <ul class="social" style="display: flex">
          <li><a href="https://t.me/hseipaa"><img src="../assets/img/icon_tg.svg" alt="icon_tg" /></a></li>
          <li><a href="https://www.facebook.com/hseipaa.kz/"><img src="../assets/img/icon_fb.svg" alt="icon_fb" /></a></li>
          <li><a href="https://www.youtube.com/hseipaa"><img src="../assets/img/icon_youtube.svg" alt="icon_youtube" /></a></li>
          <li><a href="https://www.instagram.com/hseipaa.kz/"><img src="../assets/img/icon_inst.svg" alt="icon_inst" /></a></li>
        </ul>
      </vue-final-modal>
      <vue-final-modal
        classes="modal-container"
        content-class="modal-content"
        v-model="modals.contacts"
        attach="#app-modal"
        :lock-scroll="false"
        name="menuContacts"
      >
        <h3>
          Контакты
          <svg
            @click="toggleModal('contacts')"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#F5F5F7" />
            <path
              d="M8 8L16 16"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 16L16 8"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 8L16 16"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 16L16 8"
              stroke="#6F7893"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h3>

        <ul class="contact-modal-actions">
          <li @click="$vfm.show('modalForm')">
            <img src="../assets/img/contacts-modal/button-send.svg" alt="" />
            Оставить заявку на обучение
          </li>
          <li>
            <img src="../assets/img/contacts-modal/button-call.svg" alt="" />
            Позвонить
          </li>
          <li>
            <img
              src="../assets/img/contacts-modal/button-whatsapp.svg"
              alt=""
            />
            Написать в WhatsApp
          </li>
          <li>
            <img src="../assets/img/contacts-modal/button-tg.svg" alt="" />
            Написать в Telegram
          </li>
        </ul>
      </vue-final-modal>
    </div>
    <div id="mobile-nav">
      <ul class="mobile-nav">
        <li>
          <div
            class="mobile-nav-link"
            :class="activeIcon === 'main' ? 'primary-red' : ''"
            @click="handleMain()"
          >
            <div class="nav-icon-wrapper">
              <img
                v-if="activeIcon === 'main'"
                src="../assets/img/mobile_nav_icons/home_active.svg"
                alt="nav-icon"
              />
              <img
                v-else
                src="../assets/img/mobile_nav_icons/home.svg"
                alt="nav-icon"
              />
            </div>
            <span>Главная</span>
          </div>
        </li>
        <li>
          <a
            href='https://on.hseipaa.kz/courses'
            class="mobile-nav-link"
            :class="activeIcon === 'courses' ? 'primary-red' : ''"
          >
            <div class="nav-icon-wrapper">
              <img
                src="../assets/img/mobile_nav_icons/courses.svg"
                alt="nav-icon"
              />
            </div>
            <span>Курсы</span>
          </a>
        </li>
        <li>
          <a
            href='https://on.hseipaa.kz/login'
            class="mobile-nav-link"
            :class="activeIcon === 'login' ? 'primary-red' : ''"
          >
            <div class="nav-icon-wrapper">
              <img
                src="../assets/img/mobile_nav_icons/login.svg"
                alt="nav-icon"
              />
            </div>
            <span>Вход</span>
          </a>
        </li>
        <li>
          <div
            class="mobile-nav-link"
            :class="activeIcon === 'contacts' ? 'primary-red' : ''"
            @click="toggleModal('contacts')"
          >
            <div class="nav-icon-wrapper">
              <img
                v-if="activeIcon === 'contacts'"
                src="../assets/img/mobile_nav_icons/contacts_active.svg"
                alt="nav-icon"
              />
              <img
                v-else
                src="../assets/img/mobile_nav_icons/contacts.svg"
                alt="nav-icon"
              />
            </div>
            <span>Контакты</span>
          </div>
        </li>
        <li>
          <div
            class="mobile-nav-link"
            :class="activeIcon === 'menu' ? 'primary-red' : ''"
            @click="toggleModal('menu')"
          >
            <div class="nav-icon-wrapper">
              <img
                v-if="activeIcon === 'menu'"
                src="../assets/img/mobile_nav_icons/menu_active.svg"
                alt="nav-icon"
              />
              <img
                v-else
                src="../assets/img/mobile_nav_icons/menu.svg"
                alt="nav-icon"
              />
            </div>
            <span>Меню</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      modals: {
        contacts: false,
        menu: false,
      },
      activeIcon: "main",
    };
  },
  methods: {
    toggleModal(payload) {
      this.modals[payload] = !this.modals[payload];
      for (let i in this.modals) {
        if (i !== payload) {
          this.modals[i] = false;
        }
      }
      this.init();
      // console.log(this.$vfm)
    },
    init() {
      if (this.modals.contacts) {
        this.activeIcon = "contacts";
      } else if (this.modals.menu) {
        this.activeIcon = "menu";
      } else {
        this.activeIcon = "main";
      }

      this.showModal = false;
      for (let i in this.modals) {
        if (this.modals[i]) {
          this.showModal = true;
        }
      }
    },
    closeAllModals() {
      this.$vfm.hideAll();
      window.scrollTo(0, 0);
    },
    handleMain(){
      this.closeAllModals();
      this.$router.push('/')
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    modals: {
      handler() {
        this.init();
        console.log("modals changed");
      },
      deep: true,
    },
  },
};
</script>

<style></style>
