<template>
  <transition name="fade">
    <div id="scroll-top" v-if="visible" @click="scrollTop">
      <img src="../../assets/img/back-to-top.png" alt="scroll-to-top" />
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: window.scrollY > 300,
    };
  },
  methods: {
    scrollTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.visible = window.scrollY > 300;
    });
  },
};
</script>

<style scoped lang="scss">
@import "../../_vars.scss";

#scroll-top {
  position: fixed;
  z-index: 998;
  right: 2rem;
  bottom: 7rem;
  width: 58px;
  height: 58px;
  display: flex;
  border-radius: 50%;
  // border: solid $primary_gray 2px;
  background-color: $lightgray;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    // transform: rotate(180deg);
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
