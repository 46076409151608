<template>
  <div class="header-switch-wrapper" v-if="payload">
    <div class="container header-switch">
      <div class="links">
        <a v-for="item in items" :key="item.id" :href="item.url" :class="{'primary-red' : item.position === 1, 'primary-gray' : item.position !== 1, 'bg-white active': item.active && item.active !== null}" @mouseenter="!item.active && addBg($event)" @mouseleave="!item.active && removeBg($event)">
          <img src="../assets/img/logo.svg" alt="logo" />
          <span>{{item.title}}</span>
        </a>
      </div>
      <div class="phone-number-dropdown" v-click-outside="closeDropdown">
        <strong>{{ number }}</strong>
        <div class="dropdown-city primary-gray" @click="toggleDropdown()">
          {{ city }}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10L12 16L19 10"
              stroke="#6F7893"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="dropdown" :class="dropdownActive ? 'active' : 'disabled'">
          <div class="dropdown-col">
            <ul>
              <li class="col-header">Телефоны:</li>
              <li v-for="item in filteredItems" :key="item.id">
                <span @click="changeCity(item)">{{ item.title }}</span>
                <a class="primary-red" :href="`tel:${item.description}`">{{
                  item.description
                }}</a>
              </li>
              <!-- <li class="col-header">Почта:</li>
              <li>
                <span
                  @click="
                    city = 'Обучение';
                    number = 'info@hseipaa.kz';
                    closeDropdown();
                  "
                  >Обучение</span
                >
                <a class="primary-red" href="mailto:info@hseipaa.kz"
                  >info@hseipaa.kz</a
                >
              </li>
              <li>
                <span
                  @click="
                    city = 'Для писем';
                    number = 'info@hseipaa.kz';
                    closeDropdown();
                  "
                  >Для писем</span
                >
                <a class="primary-red" href="mailto:info@hseipaa.kz"
                  >info@hseipaa.kz</a
                >
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const numbers = {
  Алматы: "+ 7 (727) 224 21 21",
  "Нур-Султан": "+7 (7172) 97 23 24",
  Актау: "+7 (729) 278 86 04",
  Атырау: "+7 (7122) 71 80 12",
  Караганда: "+7 (721) 290 24 26",
  Тараз: "+7 (726) 293 66 24",
  Шымкент: "+7 (725) 297 35 64",
  "Call centre": "+ 7 (727) 224 21 21",
};
export default {
  props: ['payload'],
  data() {
    return {
      dropdownActive: false,
      numbers: { ...numbers },
      city: '',
      number: '',
      items: []
    };
  },
  methods: {
    closeDropdown() {
      this.dropdownActive = false;
    },
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive;
    },
    changeCity(payload) {
      this.city = payload.title;
      this.number = payload.description;
      this.closeDropdown();
    },
    addBg(event){
      event.target.classList.add('bg-white')
    },
    removeBg(event){
      event.target.classList.remove('bg-white')
    }
  },
  computed: {
    filteredItems(){
      let temp = this.payload.items
      return temp.sort((a, b) => a.position - b.position)
    }
  },
  mounted(){
    this.changeCity(this.payload.first)
    this.items = this.$store.state.content.links.items.sort((a, b) => a.position - b.position)
  }
};
</script>

<style lang="scss">
@import "../_vars.scss";

.header-switch-wrapper {
  background-color: $lightgray;

  @media screen and (max-width: 571px) {
    display: none;
  }
}
.header-switch {
  display: flex;
  justify-content: space-between;
  .links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;

    div,
    a {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      gap: 0.5rem;

      img {
        height: 1.8rem;
        width: 1.8rem;
      }
    }

    .bg-white, .active {
      border-radius: 1rem 1rem 0 0;
      // cursor: default;
    }
    .active{
      cursor: default;
      pointer-events: none;
    }
  }
  .phone-number-dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;

    strong {
      font-size: 18px;
    }
    .dropdown-city {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: underline;
    }
    .dropdown-city:hover {
      text-decoration: none;
    }
    .dropdown {
      top: 3.5rem;
      right: 0;
      background-color: $lightgray;

      ul {
        margin: 0;
        padding: 1rem 0;
        list-style: none;
        li {
          padding: 0.5rem 1rem;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          white-space: nowrap;
          gap: 1rem;
          a {
            font-weight: 600;
          }
          span:hover {
            text-decoration: underline;
          }
        }
      }

      .col-header {
        font-weight: 600;
      }
    }
  }
}
</style>
