<template>
  <div class="reviews-wrapper" v-if="payload">
    <div class="container">
      <h1 class="white">{{payload.title}}</h1>
      <div class="reviews">
        <review
          v-for="(review, index) in payload.items"
          :key="index"
          :review="review"
        ></review>
      </div>
    </div>
  </div>
</template>

<script>
import Review from "../components/UI/Review.vue";
export default {
  components: { Review },
  props: ['payload'],
  data() {
    return {
      reviews: [
        {
          title: "Раушан Саткеева",
          description:
            "Гаухар Женисова, спасибо Вам огромное за курс по Налогам, все было понятно и информативно! чувствовалась Ваша искреннее пожелание научить и делится своими знаниями и навыками с нами. <br/> <br/> Организаторам также большое спасибо, за организацю и обратную связь, и за грамотный подход в выборе преподавателя и в организации учебы!",
          city: "г. Кызылорда",
        },
        {
          title: "Ольга",
          description:
            "Добрый день. Отзыв по обучению.Профессиональный медиатор. Прослушав курс лекций я получила много новых знаний. Материал подан в доступной форме, слайды великолепные. Практические занятия позволили применять разные инструменты медиаторы. Огромная благодарность Нурбале Алтынкановне за знания. Планирую обучение на курсе международная медиация.",
        },
        {
          title: "Орик Какимовна",
          description:
            "Я успешна прошла обучение в Высшей школе экономики на курсах професииональных медиаторов. Тренер Оразкулова Нурбала Алтынкановна. Во время обучения получила очень много материала, которые пригодятся в моей дальнейшей работе медиатора. Также проверили профессиональные навыки в форме сторон. Именно эти навыки и помогают понять роль медиатора в оказании помощи гражданам.",
        },
        {
          title: "Ольга",
          description:
            "Добрый день. Отзыв по обучению.Профессиональный медиатор. Прослушав курс лекций я получила много новых знаний. Материал подан в доступной форме, слайды великолепные. Практические занятия позволили применять разные инструменты медиаторы. Огромная благодарность Нурбале Алтынкановне за знания. Планирую обучение на курсе международная медиация.",
        },
      ],
    };
  }
};
</script>

<style lang="scss"></style>
