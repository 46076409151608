<template>
  <header class="container" v-if="payload">
    <div class="header-inner">
      <router-link to="/" class="header-image">
        <img src="../assets/img/logo_label.svg" alt="" />
      </router-link>
      <ul class="header-links" @mouseout="dropdownActive = false">
        <li
          @mouseover="dropdownActive = true"
          class="dropdown-wrapper"
          v-click-outside="closeDropdown"
        >
          <a
            href="https://on.hseipaa.kz/courses"
            target="_blank"
            :class="dropdownActive ? 'primary-red' : ''"
            >Курсы</a
          >
          <svg
            v-if="dropdownActive"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10L12 16L19 10"
              stroke="#EF533F"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-else
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10L12 16L19 10"
              stroke="#6F7893"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div
            @mouseover="dropdownActive = true"
            class="dropdown dropdown-list dropdown-header"
            :class="dropdownActive ? 'active' : 'disabled'"
          >
              <router-link
                v-for="page in Object.keys(payload)"
                :key="payload[page].key"
                :to="`/about/${page}`"
                >{{payload[page].title}}</router-link
              >
          </div>
        </li>
        <li>
          <router-link active-class="primary-red" to="/about"
            >О нас</router-link
          >
        </li>
        <li>
          <router-link active-class="primary-red" to="/contacts"
            >Контакты</router-link
          >
        </li>
        <li><a target="_blank" class="link-orange" href="https://hseipaa.kz/">hseipaa.kz</a></li>
      </ul>
      <a href="/courses" class="primary-red login-button">
        Войти
        <img src="../assets/img/icon_login.svg" alt="" />
      </a>
      <ul class="social">
        <li><a target='_blank' :href="links.telegram"><img src="../assets/img/icon_tg.svg" alt="icon_tg" /></a></li>
        <li><a target='_blank' :href="links.facebook"><img src="../assets/img/icon_fb.svg" alt="icon_fb" /></a></li>
        <li><a target='_blank' :href="links.youtube"><img src="../assets/img/icon_youtube.svg" alt="icon_youtube" /></a></li>
        <li><a target='_blank' :href="links.instagram"><img src="../assets/img/icon_inst.svg" alt="icon_inst" /></a></li>
      </ul>

      <ul class="header-lang">
        <li
          class="lang"
          @click="changeLang('kk')"
          :class="$store.state.lang == 'kk' ? 'active' : ''"
          data-google-lang="kk"
        >
          Қаз
        </li>
        <li
          class="lang"
          @click="changeLang('ru')"
          :class="$store.state.lang == 'ru' ? 'active' : ''"
          data-google-lang="ru"
        >
          Рус
        </li>
        <li
          class="lang"
          @click="changeLang('en')"
          :class="$store.state.lang == 'en' ? 'active' : ''"
          data-google-lang="en"
        >
          Eng
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      dropdownActive: false,
    };
  },
  props: ['payload', 'links'],
  methods: {
    changeLang(lang) {
      this.$store.dispatch("changeLang", lang);
    },
    closeDropdown() {
      this.dropdownActive = false;
    },
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive;
    },
  },
};
</script>

<style></style>
