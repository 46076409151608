<template>
  <div class="home">
    <main-banner :payload="content.slider"></main-banner>
    <section-cards></section-cards>
    <advantages></advantages>
    <steps :payload="content.step"></steps>
    <certificate :payload="content.diploma"></certificate>
    <partners :payload="partners1"></partners>
    <why-us :payload="content.whyus"></why-us>
    <reviews :payload="content.review"></reviews>
    <partners :payload="partners2"></partners>
    <div class="container">
      <accordion :items="accordionItems"></accordion>
    </div>
    <div class="container">
      <app-form></app-form>
    </div>
  </div>
</template>

<script>
import MainBanner from "../components/MainBanner.vue";
import SectionCards from "../components/SectionCards.vue";
import Advantages from "../components/Advantages.vue";
import Steps from "../components/Steps.vue";
import Certificate from "../components/Certificate.vue";
import Partners from "../components/Partners.vue";
import WhyUs from "../components/WhyUs.vue";
import Reviews from "../components/Reviews.vue";
import Accordion from "../components/UI/Accordion.vue";
import AppForm from "../components/Form.vue";
// import ModalForm from '../components/ModalForm.vue'

import partners_img1 from "../assets/img/partners/damu.svg";
import partners_img2 from "../assets/img/partners/kazpost.svg";
import partners_img3 from "../assets/img/partners/kmg.svg";
import partners_img4 from "../assets/img/partners/ktg_aimak.svg";
import partners_img5 from "../assets/img/partners/ktzh.svg";
import partners_img6 from "../assets/img/partners/samruq.svg";
import partners_img7 from "../assets/img/partners/telecom.svg";
import partners_img8 from "../assets/img/partners/more.svg";

import partners1_img1 from "../assets/img/partners2/association.svg";
import partners1_img2 from "../assets/img/partners2/general_re.svg";
import partners1_img3 from "../assets/img/partners2/hottour.svg";
import partners1_img4 from "../assets/img/partners2/polisre.svg";
import partners1_img5 from "../assets/img/partners2/school.svg";

export default {
  components: {
    MainBanner,
    SectionCards,
    Advantages,
    Steps,
    Certificate,
    Partners,
    WhyUs,
    Reviews,
    Accordion,
    AppForm,
    // ModalForm,
  },
  methods: {
    showModal(){
      this.$vfm.show('modalForm')
    }
  },
  data() {
    return {
      content: {},
      partners1: {
        images: [
          partners_img2,
          partners_img3,
          partners_img4,
          partners_img5,
          partners_img6,
          partners_img1,
          partners_img7,
          partners_img8,
        ],
        button:
          '<button class="btn-main primary-red-outlined">Стать клиентом</button>',
        description:
          "Высшей Школы Экономики являются казахстанские и международные компании малого, среднего и крупного бизнеса из различных сфер. Мы осуществляем полное обеспечение потребностей заказчика и индивидуальный подход к каждому клиенту.",
        header: "Мы ценим долгосрочные отношения",
        class: "bg-primary-black white",
        onClick: this.showModal
      },
      partners2: {
        images: [
          partners1_img1,
          partners1_img2,
          partners1_img3,
          partners1_img4,
          partners1_img5,
        ],
        description:
          "Высшая Школа Экономики с большим вниманием относится к выбору партнёров в различных сферах. Работа строится только с теми компаниями которые зарекомендовали себя как профессиональное учреждение и уже внесли вклад в становление и развитие образовательного пространства либо отрасли",
        header: "Партнеры",
      },
      accordionItems: [
        {
          title: "Когда проводится обучение?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
        {
          title: "Какова стоимость обучения?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
        {
          title: "Кто проводит обучения?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
        {
          title: "Где проводится обучение?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
        {
          title: "Когда проводится обучение?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
        {
          title: "Продолжительность курса?",
          description:
            "Обучение проводится по факту укомплектования групп, время обучения согласовывается с участниками курса.",
        },
      ],
    };
  },
  mounted(){
    this.content = this.$store.state.content
    // console.log(this.content)
    this.partners1.images = this.content.clients.items
    this.partners1.description = this.content.clients.text
    this.partners1.header = this.content.clients.title
    this.partners2.images = this.content.partner.items
    this.partners2.description = this.content.partner.text
    this.partners2.header = this.content.partner.title

    this.accordionItems = this.content.faq.items
  }
};
</script>
