<template>
  <section class="sectionCards-wrapper">
    <div class="container">
      <div class="sectionCards-description">
        <div v-html="text_1" class="left sectionCards-quote">
        </div>
        <div v-html="text_2" class="right sectionCards-quote">
        </div>
      </div>
      <div class="sectionCards-content">
        <card
          v-for="course in landingCourses"
          :key="course"
          :course="course"
        ></card>
      </div>

      <div
        class="show-more"
      >
        <a href="https://on.hseipaa.kz/courses" target="_blank">
          <button
          class="btn-show-more btn-green btn-main"
        >
          Показать еще
        </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "./UI/Card.vue";
import axios from "axios";

export default {
  components: { Card },
  data() {
    return {
      courses: [],
      show: 6,
      text_1: '',
      text_2: '',
    };
  },
  computed: {
    paginatedCourses() {
      if (this.show < this.courses.length) {
        return this.courses.slice(0, this.show);
      }
      return this.courses;
    },
    landingCourses(){
      return this.courses.filter(course => course.visible)
    }
  },
  methods: {
    async getCourses() {
      axios.get("https://on.hseipaa.kz/api/custom/courses").then((res) => {
        console.log(res)
        this.courses = res.data.courses.data;
      });
    },
  },
  mounted() {
    this.getCourses();
    this.text_1 = this.$store.state.content.under_slider.text1
    this.text_2 = this.$store.state.content.under_slider.text2
  },
};
</script>

<style></style>
