import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VModal from 'vue-js-modal/dist/index.nocss.js'
import "vue-js-modal/dist/styles.css";
import vfmPlugin from "vue-final-modal";

const app = createApp(App);
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        // console.log('event.target:', event.target, '\nel:', el)
        // console.log('el.contains(event.target)', el.contains(event.target))
        // console.log('binding:', binding)
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
app.use(store);
app.use(router);
app.use(
  vfmPlugin({
    key: "$vfm",
    componentName: "VueFinalModal",
    dynamicContainerName: "ModalsContainer",
  })
);
// app.use(VModal)
app.directive("click-outside", clickOutside);
app.mount("#app");
