<template>
  <div class="certificate-wrapper" v-if="payload">
    <div class="certificate container">
      <div class="certificate-description">
        <span class="diplomas primary-red"> Дипломы </span>

        <h2 class="certificates-header white">
          {{payload.title}}
        </h2>
        <div v-html="payload.text"></div>
      </div>
      <div class="certificate-image">
        <img :src="poster" alt="certificate-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['payload'],
  computed: {
    poster(){
      if (this.payload.poster.startsWith('https://on.hseipaa.kz/')) return this.payload.poster
      else return 'https://on.hseipaa.kz/' + this.payload.poster
    }
  }
};
</script>

<style></style>
